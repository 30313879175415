import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n
    .use(initReactI18next)
    .init({
        lng: 'en', 
        fallbackLng: 'ptBR',
        debug: true,
        resources: {
            ptBR: {
                translation: {
                    'Selecione o idioma': 'Selecione o idioma',
                    'Dados do': 'Dados do',
                    'assinante': 'assinante',
                    'comprador': 'comprador',
                    'Nome completo': 'Nome completo',
                    'E-mail': 'E-mail',
                    'CPF': 'CPF',
                    'Celular com DDD': 'Celular com DDD',
                    '(99) 9 9999-9999':'(99) 9 9999-9999',
                    'CEP':'CEP',
                    'Não sei meu CEP':'Não sei meu CEP',
                    'Endereço':'Endereço',
                    'Número':'Número',
                    'Complemento':'Complemento',
                    'Sou residente no exterior':'Sou residente no exterior',
                    'Dados adicionais':'Dados adicionais',
                    'Frete':'Frete',
                    'Chegará em até':'Chegará em até',
                    'dias':'dias',
                    'Forma de pagamento':'Forma de pagamento',
                    'Algumas formas de pagamento foram desabilitadas':'Algumas formas de pagamento foram desabilitadas',
                    'Você selecionou uma oferta por assinatura, por esse motivo só será possível efetuar o pagamento no cartão de crédito':'Você selecionou uma oferta por assinatura, por esse motivo só será possível efetuar o pagamento no cartão de crédito',
                    'Cartão':'Cartão',
                    'Pix':'Pix',
                    'Boleto':'Boleto',
                    'Titular do Cartão de Crédito':'Titular do Cartão de Crédito',
                    'Número do Cartão de Crédito':'Número do Cartão de Crédito',
                    'Mês de Vencimento':'Mês de Vencimento',
                    'Ano de Vencimento':'Ano de Vencimento',
                    'CVV':'CVV',
                    'Parcelas':'Parcelas',
                    'Informações sobre o pagamento via Pix:':'Informações sobre o pagamento via Pix:',
                    'Geramos os dados de pagamento após você clicar em':'Geramos os dados de pagamento após você clicar em',
                    'pagar agora':'pagar agora',
                    'Você pode pagar por QR Code ou Pix Copia e Cola.':'Você pode pagar por QR Code ou Pix Copia e Cola.',
                    'A liberação é feita em poucos minutos.':'A liberação é feita em poucos minutos.',
                    'Super seguro. O pagamento Pix foi desenvolvido pelo Banco Central para facilitar pagamentos.':'Super seguro. O pagamento Pix foi desenvolvido pelo Banco Central para facilitar pagamentos.',
                    'Informações sobre o pagamento via Boleto Bancário:':'Informações sobre o pagamento via Boleto Bancário:',
                    'Geramos o boleto e código de barras após você clicar em':'Geramos o boleto e código de barras após você clicar em',
                    'Você pode pagar via internet banking, caixas bancários ou em casas lotéricas até o vencimento.':'Você pode pagar via internet banking, caixas bancários ou em casas lotéricas até o vencimento.',
                    'A liberação é feita de 1 a 3 dias úteis.':'A liberação é feita de 1 a 3 dias úteis.',
                    'Falha no pagamento':'Falha no pagamento',
                    'Retorno do sistema':'Retorno do sistema',
                    'PAGAR AGORA':'PAGAR AGORA',
                    'Nós protegemos seus dados de pagamento usando criptografia para prover segurança ao nível bancário.':'Nós protegemos seus dados de pagamento usando criptografia para prover segurança ao nível bancário.',
                    'Resumo da sua':'Resumo da sua',
                    'assinatura':'assinatura',
                    'compra':'compra',
                    'pago':'pago',
                    'por um período de':'por um período de',
                    'Parcelado em':'Parcelado em',
                    'de':'de',
                    'Total':'Total',
                    'Nós protegemos seus dados de pagamento usando criptografia à nível bancário para prover segurança  na compra.':'Nós protegemos seus dados de pagamento usando criptografia à nível bancário para prover segurança  na compra.',
                    'Pagamento aprovado':'Pagamento aprovado',
                    'Pagamento Recusado':'Pagamento Recusado',
                    'O pagamento foi autorizado! Você receberá uma confirmação da compra por e-mail.':'O pagamento foi autorizado! Você receberá uma confirmação da compra por e-mail.',
                    'Pagamento por Pix':'Pagamento por Pix',
                    'Pedido efetuado! Agora finalize o pagamento':'Pedido efetuado! Agora finalize o pagamento',
                    'Abra o app do seu banco e entre na opção Pix':'Abra o app do seu banco e entre na opção Pix',
                    'Escolha a opção':'Escolha a opção',
                    'Pagar Pix copia e cola':'Pagar Pix copia e cola',
                    'ou':'ou',
                    'Scanear QR Code':'Scanear QR Code',
                    'Realize o pagamento':'Realize o pagamento',
                    'A confirmação é feita em poucos minutos, essa tela':'A confirmação é feita em poucos minutos, essa tela',
                    'atualizará automaticamente':'atualizará automaticamente',
                    'COPIAR CÓDIGO PIX':'COPIAR CÓDIGO PIX',
                    'TOTAL':'TOTAL',
                    'Pedido gerado! Agora finalize o pagamento':'Pedido gerado! Agora finalize o pagamento',
                    'Pague pelo seu internet banking, lotérica ou direto na agência bancária':'Pague pelo seu internet banking, lotérica ou direto na agência bancária',
                    'Pagável até a data do vencimento':'Pagável até a data do vencimento',
                    'A confirmação é feita em até 3 dias úteis':'A confirmação é feita em até 3 dias úteis',
                    'IMPRIMIR BOLETO':'IMPRIMIR BOLETO',
                    'Pagamento em análise':'Pagamento em análise',
                    'A confirmação é feita em poucos minutos':'A confirmação é feita em poucos minutos',
                    'Quando o pagamento for identificado, a tela será atualizada automaticamente e você também receberá uma confirmação por e-mail.':'Quando o pagamento for identificado, a tela será atualizada automaticamente e você também receberá uma confirmação por e-mail.',
                    'Ainda não fez o pagamento?':'Ainda não fez o pagamento?',
                    'Seu pagamento via Pix foi recebido com sucesso, um e-mail com detalhes da sua compra foi enviado.':'Seu pagamento via Pix foi recebido com sucesso, um e-mail com detalhes da sua compra foi enviado.',
                    'Formas de Pagamento':'Formas de Pagamento',
                    'Carrinho':'Carrinho',
                    'Pagamento':'Pagamento',
                    'Confirmação':'Confirmação',
                    'Processando pagamento, aguarde...':'Processando pagamento, aguarde...',
                    'semanalmente':'semanalmente',
                    'semanas':'semanas',
                    'mensalmente':'mensalmente',
                    'meses':'meses',
                    'a cada 2 meses':'a cada 2 meses',
                    'bimestres':'bimestres',
                    'a cada 3 meses':'a cada 3 meses',
                    'trimestres':'trimestres',
                    'a cada 6 meses':'a cada 6 meses',
                    'semestres':'semestres',
                    'anualmente':'anualmente',
                    'anos':'anos',
                    'Não foi possível realizar a compra':'Não foi possível realizar a compra',
                    'Preencha todas as informações obrigatórias dos campos.':'Preencha todas as informações obrigatórias dos campos.',
                    'Não foi possível realizar o pagamento usando este cartão de crédito.':'Não foi possível realizar o pagamento usando este cartão de crédito.',
                    'Tente novamente utilizando outro cartão ou forma de pagamento':'Tente novamente utilizando outro cartão ou forma de pagamento',
                    'Selecione a quantidade de parcelas':'Selecione a quantidade de parcelas',
                    'Você deve escolher o método de envio':'Você deve escolher o método de envio',
                    'CVV Inválido':'CVV Inválido',
                    'Você deve preencher este campo':'Você deve preencher este campo',
                    'Vencimento inválido':'Vencimento inválido',
                    'Selecione o ano de vencimento':'Selecione o ano de vencimento',
                    'Selecione o mês de vencimento':'Selecione o mês de vencimento',
                    'Digite um número de cartão válido':'Digite um número de cartão válido',
                    'Não aceitamos a bandeira informada':'Não aceitamos a bandeira informada',
                    'Informe um':'Informe um',
                    'CNPJ':'CNPJ',
                    'Você deve informar um CEP válido':'Você deve informar um CEP válido',
                    'Campo Obrigatório':'Campo Obrigatório',
                    'Informe o nome completo':'Informe o nome completo',
                    'Informe o nome e sobrenome do titular do cartão':'Informe o nome e sobrenome do titular do cartão',
                    'Informe um telefone válido':'Informe um telefone válido',
                    'Não conseguimos encontrar o CEP informado.':'Não conseguimos encontrar o CEP informado.',
                    'Finalizar Compra':'Finalizar Compra',
                    'Segurança': 'Segurança',
                    'Nós protegemos seus dados de pagamento usando criptografia à nível bancário para prover segurança na compra.': 'Nós protegemos seus dados de pagamento usando criptografia à nível bancário para prover segurança na compra.',
                    'Em até': 'Em até',
                    'dias úteis': 'dias úteis',
                    'Não existem opções de frete disponíveis para sua região': 'Não existem opções de frete disponíveis para sua região',
                    'Desconto': 'Desconto',
                    'Frete grátis': 'Frete grátis',
                    "Selecione a data da reserva": "Selecione a data da reserva",
                    "Dom":"Dom",
                    "Seg":"Seg",
                    "Ter":"Ter",
                    "Qua":"Qua",
                    "Qui":"Qui",
                    "Sex":"Sex",
                    "Sab":"Sab",
                    "OK":"OK",
                    "Hoje":"Hoje",
                    "Ontem":"Ontem",
                    "Horas":"Horas",
                    "Minutos":"Minutos",
                    "Segundos":"Segundos",
                    "Selecionar data":"Selecionar data",
                    "DD/MM/YYYY": "DD/MM/YYYY",
                    "Tarifa de conveniência": "Tarifa de conveniência",
                    "Você precisa informar a data da reserva": "Você precisa informar a data da reserva",
                }, //    t('')
            },
            en: {
                translation: {
                    'Selecione o idioma': 'Select your language',
                    'Dados do': 'Information of',
                    'assinante': 'subscriber',
                    'comprador': 'customer',
                    'Nome completo': 'Full name',
                    'E-mail': 'Email',
                    'CPF': 'CPF',
                    'Celular com DDD': 'Cellphone with area code',
                    '(99) 9 9999-9999': '(99) 9 9999-9999',
                    'CEP': 'ZIP code',
                    'Não sei meu CEP': 'I don\'t know my ZIP code',
                    'Endereço': 'Address',
                    'Número': 'Number',
                    'Complemento': 'Complement',
                    'Sou residente no exterior': 'I am a resident abroad',
                    'Dados adicionais': 'Additional data',
                    'Frete': 'Shipping',
                    'Chegará em até': 'Will arrive in up to',
                    'dias': 'days',
                    'Forma de pagamento': 'Payment method',
                    'Algumas formas de pagamento foram desabilitadas': 'Some payment methods have been disabled',
                    'Você selecionou uma oferta por assinatura, por esse motivo só será possível efetuar o pagamento no cartão de crédito': 'You selected a subscription offer, so payment can only be made by credit card',
                    'Cartão': 'Credit card',
                    'Pix': 'Pix',
                    'Boleto': 'Bank Slip',
                    'Titular do Cartão de Crédito': 'Credit Card Holder',
                    'Número do Cartão de Crédito': 'Credit Card Number',
                    'Mês de Vencimento': 'Expiration Month',
                    'Ano de Vencimento': 'Expiration Year',
                    'CVV': 'CVV',
                    'Parcelas': 'Installments',
                    'Informações sobre o pagamento via Pix:': 'Information about Pix payment:',
                    'Geramos os dados de pagamento após você clicar em': 'We generate payment details after you click on',
                    'pagar agora': 'pay now',
                    'Você pode pagar por QR Code ou Pix Copia e Cola.': 'You can pay by QR Code or Pix Copy and Paste.',
                    'A liberação é feita em poucos minutos.': 'Release is done in a few minutes.',
                    'Super seguro. O pagamento Pix foi desenvolvido pelo Banco Central para facilitar pagamentos.': 'Super secure. Pix payment was developed by the Central Bank to facilitate transactions.',
                    'Informações sobre o pagamento via Boleto Bancário:': 'Information about payment via Bank slip:',
                    'Geramos o boleto e código de barras após você clicar em': 'We generate the bank slip and barcode after you click on',
                    'Você pode pagar via internet banking, caixas bancários ou em casas lotéricas até o vencimento.': 'You can pay via internet banking, bank branches, or in lottery houses until the due date.',
                    'A liberação é feita de 1 a 3 dias úteis.': 'Release is done in 1 to 3 business days.',
                    'Falha no pagamento': 'Payment failure',
                    'Retorno do sistema': 'System return',
                    'PAGAR AGORA': 'PAY NOW',
                    'Nós protegemos seus dados de pagamento usando criptografia para prover segurança ao nível bancário.': 'We protect your payment data using encryption to provide bank-level security.',
                    'Resumo da sua': 'Summary of your',
                    'assinatura': 'subscription',
                    'compra': 'purchase',
                    'pago': 'paid',
                    'por um período de': 'for a period of',
                    'Parcelado em': 'Installments of',
                    'de': 'of',
                    'Total': 'Total',
                    'Nós protegemos seus dados de pagamento usando criptografia à nível bancário para prover segurança na compra.': 'We protect your payment data using encryption at the bank level to provide security in the purchase.',
                    'Pagamento aprovado': 'Payment approved',
                    'Pagamento Recusado': 'Payment declined',
                    'O pagamento foi autorizado! Você receberá uma confirmação da compra por e-mail.': 'Payment has been authorized! You will receive a purchase confirmation by email.',
                    'Pagamento por Pix': 'Pix payment',
                    'Pedido efetuado! Agora finalize o pagamento': 'Order placed! Now finalize the payment',
                    'Abra o app do seu banco e entre na opção Pix': "Open your bank's app and go to the Pix option",
                    'Escolha a opção': 'Choose the option',
                    'Pagar Pix copia e cola': 'Pay Pix copy and paste',
                    'ou': 'or',
                    'Scanear QR Code': 'Scan QR Code',
                    'Realize o pagamento': 'Make the payment',
                    'A confirmação é feita em poucos minutos, essa tela': 'Confirmation is done in a few minutes, this screen',
                    'atualizará automaticamente': 'will update automatically',
                    'COPIAR CÓDIGO PIX': 'COPY PIX CODE',
                    'TOTAL': 'TOTAL',
                    'Pedido gerado! Agora finalize o pagamento': 'Order generated! Now finalize the payment',
                    'Pague pelo seu internet banking, lotérica ou direto na agência bancária': 'Pay through your internet banking, lottery, or directly at the bank branch',
                    'Pagável até a data do vencimento': 'Payable until the due date',
                    'A confirmação é feita em até 3 dias úteis': 'Confirmation is done within 3 business days',
                    'IMPRIMIR BOLETO': 'PRINT BANK SLIP',
                    'Pagamento em análise': 'Payment under review',
                    'A confirmação é feita em poucos minutos': 'Confirmation is done in a few minutes',
                    'Quando o pagamento for identificado, a tela será atualizada automaticamente e você também receberá uma confirmação por e-mail.': 'When the payment is identified, the screen will be updated automatically, and you will also receive a confirmation by email.',
                    'Ainda não fez o pagamento?': 'Haven\'t made the payment yet?',
                    'Seu pagamento via Pix foi recebido com sucesso, um e-mail com detalhes da sua compra foi enviado.': 'Your Pix payment has been received successfully, an email with details of your purchase has been sent.',
                    'Formas de Pagamento': 'Payment Methods',
                    'Carrinho': 'Cart',
                    'Pagamento': 'Payment',
                    'Confirmação': 'Confirmation',
                    'Processando pagamento, aguarde...': 'Processing payment, please wait...',
                    'semanalmente': 'weekly',
                    'semanas': 'weeks',
                    'mensalmente': 'monthly',
                    'meses': 'months',
                    'a cada 2 meses': 'every 2 months',
                    'bimestres': 'bimonthly',
                    'a cada 3 meses': 'every 3 months',
                    'trimestres': 'quarterly',
                    'a cada 6 meses': 'every 6 months',
                    'semestres': 'semiannually',
                    'anualmente': 'annually',
                    'anos': 'years',
                    'Não foi possível realizar a compra': 'Unable to complete the purchase',
                    'Preencha todas as informações obrigatórias dos campos.': 'Fill in all required field information.',
                    'Não foi possível realizar o pagamento usando este cartão de crédito.': 'Unable to complete the payment using this credit card.',
                    'Tente novamente utilizando outro cartão ou forma de pagamento': 'Try again using another card or payment method',
                    'Selecione a quantidade de parcelas': 'Select the number of installments',
                    'Você deve escolher o método de envio': 'You must choose the shipping method',
                    'CVV Inválido': 'Invalid CVV',
                    'Você deve preencher este campo': 'You must fill in this field',
                    'Vencimento inválido': 'Invalid expiration date',
                    'Selecione o ano de vencimento': 'Select the expiration year',
                    'Selecione o mês de vencimento': 'Select the expiration month',
                    'Digite um número de cartão válido': 'Enter a valid card number',
                    'Não aceitamos a bandeira informada': 'We do not accept the provided card brand',
                    'Informe um': 'Enter a',
                    'CNPJ': 'CNPJ',
                    'Você deve informar um CEP válido': 'You must provide a valid ZIP code',
                    'Campo Obrigatório': 'Mandatory field',
                    'Informe o nome completo': 'Enter the full name',
                    'Informe o nome e sobrenome do titular do cartão': 'Enter the name and surname of the cardholder',
                    'Informe um telefone válido': 'Enter a valid phone number',
                    'Não conseguimos encontrar o CEP informado.': 'We couldn\'t find the provided ZIP code.',
                    'Finalizar Compra': 'Complete Purchase',
                    'Segurança': 'Security',
                    'Em até': 'Will be delivered in',
                    'dias úteis': 'work days',
                    'Não existem opções de frete disponíveis para sua região': 'We can\'t deliver in your selected location',
                    'Desconto': 'Discount',
                    'Frete grátis': 'Free shipping',
                    "Selecione a data da reserva": "Select booking date",
                    "Dom": "Sun",
                    "Seg": "Mon",
                    "Ter": "Tue",
                    "Qua": "Wed",
                    "Qui": "Thu",
                    "Sex": "Fri",
                    "Sab": "Sat",
                    "OK": "OK",
                    "Hoje": "Today",
                    "Ontem": "Yesterday",
                    "Horas": "Hours",
                    "Minutos": "Minutes",
                    "Segundos": "Seconds",
                    "Selecionar data": "Select date",
                    "DD/MM/YYYY": "DD MMMM YYYY",
                    "Tarifa de conveniência": "Fee",
                    "Você precisa informar a data da reserva": "You need to choose booking date",


                }
            },
            es: {
                translation: {
                    'Selecione o idioma': 'Selecciona el idioma',
                    'Dados do': 'Datos de',
                    'assinante': 'suscriptor',
                    'comprador': 'comprador',
                    'Nome completo': 'Nombre completo',
                    'E-mail': 'Correo electrónico',
                    'CPF': 'CPF',
                    'Celular com DDD': 'Celular con código de área',
                    '(99) 9 9999-9999': '(99) 9 9999-9999',
                    'CEP': 'Código postal',
                    'Não sei meu CEP': 'No sé mi código postal',
                    'Endereço': 'Dirección',
                    'Número': 'Número',
                    'Complemento': 'Complemento',
                    'Sou residente no exterior': 'Soy residente en el extranjero',
                    'Dados adicionais': 'Datos adicionales',
                    'Frete': 'Envío',
                    'Chegará em até': 'Llegará en hasta',
                    'dias': 'días',
                    'Forma de pagamento': 'Forma de pago',
                    'Algumas formas de pagamento foram desabilitadas': 'Algunas formas de pago han sido deshabilitadas',
                    'Você selecionou uma oferta por assinatura, por esse motivo só será possível efetuar o pagamento no cartão de crédito': 'Ha seleccionado una oferta de suscripción, por lo que solo se podrá realizar el pago con tarjeta de crédito',
                    'Cartão': 'Tarjeta',
                    'Pix': 'Pix',
                    'Boleto': 'Boleto',
                    'Titular do Cartão de Crédito': 'Titular de la tarjeta de crédito',
                    'Número do Cartão de Crédito': 'Número de tarjeta de crédito',
                    'Mês de Vencimento': 'Mes de vencimiento',
                    'Ano de Vencimento': 'Año de vencimiento',
                    'CVV': 'CVV',
                    'Parcelas': 'Cuotas',
                    'Informações sobre o pagamento via Pix:': 'Información sobre el pago a través de Pix:',
                    'Geramos os dados de pagamento após você clicar em': 'Generamos los datos de pago después de hacer clic en',
                    'pagar agora': 'pagar ahora',
                    'Você pode pagar por QR Code ou Pix Copia e Cola.': 'Puede pagar con código QR o Pix Copia y Pega.',
                    'A liberação é feita em poucos minutos.': 'La liberación se realiza en pocos minutos.',
                    'Super seguro. O pagamento Pix foi desenvolvido pelo Banco Central para facilitar pagamentos.': 'Super seguro. El pago Pix fue desarrollado por el Banco Central para facilitar transacciones.',
                    'Informações sobre o pagamento via Boleto Bancário:': 'Información sobre el pago a través de Boleto Bancário:',
                    'Geramos o boleto e código de barras após você clicar em': 'Generamos el boleto y el código de barras después de hacer clic en',
                    'Você pode pagar via internet banking, caixas bancários ou em casas lotéricas até o vencimento.': 'Puede pagar a través de banca en línea, cajas bancarias o en casas lotéricas hasta la fecha de vencimiento.',
                    'A liberação é feita de 1 a 3 dias úteis.': 'La liberación se realiza de 1 a 3 días hábiles.',
                    'Falha no pagamento': 'Fallo en el pago',
                    'Retorno do sistema': 'Retorno del sistema',
                    'PAGAR AGORA': 'PAGAR AHORA',
                    'Nós protegemos seus dados de pagamento usando criptografia para prover segurança ao nível bancário.': 'Protegemos sus datos de pago mediante cifrado para brindar seguridad a nivel bancario.',
                    'Resumo da sua': 'Resumen de su',
                    'assinatura': 'suscripción',
                    'compra': 'compra',
                    'pago': 'pagado',
                    'por um período de': 'por un período de',
                    'Parcelado em': 'Pagado en',
                    'de': 'de',
                    'Total': 'Total',
                    'Nós protegemos seus dados de pagamento usando criptografia à nível bancário para prover segurança na compra.': 'Protegemos sus datos de pago mediante cifrado a nivel bancario para brindar seguridad en la compra.',
                    'Pagamento aprovado': 'Pago aprobado',
                    'Pagamento Recusado': 'Pago rechazado',
                    'O pagamento foi autorizado! Você receberá uma confirmação da compra por e-mail.': '¡El pago ha sido autorizado! Recibirá una confirmación de la compra por correo electrónico.',
                    'Pagamento por Pix': 'Pago por Pix',
                    'Pedido efetuado! Agora finalize o pagamento': '¡Pedido realizado! Ahora finalice el pago',
                    'Abra o app do seu banco e entre na opção Pix': 'Abra la aplicación de su banco e ingrese a la opción Pix',
                    'Escolha a opção': 'Elija la opción',
                    'Pagar Pix copia e cola': 'Pagar Pix copia y pega',
                    'ou': 'o',
                    'Scanear QR Code': 'Escanear código QR',
                    'Realize o pagamento': 'Realice el pago',
                    'A confirmação é feita em poucos minutos, essa tela': 'La confirmación se realiza en pocos minutos, esta pantalla',
                    'atualizará automaticamente': 'se actualizará automáticamente',
                    'COPIAR CÓDIGO PIX': 'COPIAR CÓDIGO PIX',
                    'TOTAL': 'TOTAL',
                    'Pedido gerado! Agora finalize o pagamento': '¡Pedido generado! Ahora finalice el pago',
                    'Pague pelo seu internet banking, lotérica ou direto na agência bancária': 'Pague a través de su banca en línea, lotería o directamente en la sucursal bancaria',
                    'Pagável até a data do vencimento': 'Pagable hasta la fecha de vencimiento',
                    'A confirmação é feita em até 3 dias úteis': 'La confirmación se realiza en hasta 3 días hábiles',
                    'IMPRIMIR BOLETO': 'IMPRIMIR BOLETO',
                    'Pagamento em análise': 'Pago en análisis',
                    'A confirmação é feita em poucos minutos': 'La confirmación se realiza en pocos minutos',
                    'Quando o pagamento for identificado, a tela será atualizada automaticamente e você também receberá uma confirmação por e-mail.': 'Cuando se identifique el pago, la pantalla se actualizará automáticamente y también recibirá una confirmación por correo electrónico.',
                    'Ainda não fez o pagamento?': '¿Todavía no ha realizado el pago?',
                    'Seu pagamento via Pix foi recebido com sucesso, um e-mail com detalhes da sua compra foi enviado.': 'Su pago a través de Pix se ha recibido con éxito, se ha enviado un correo electrónico con los detalles de su compra.',
                    'Formas de Pagamento': 'Formas de pago',
                    'Carrinho': 'Carrito',
                    'Pagamento': 'Pago',
                    'Confirmação': 'Confirmación',
                    'Processando pagamento, aguarde...': 'Procesando el pago, espere...',
                    'semanalmente': 'semanalmente',
                    'semanas': 'semanas',
                    'mensalmente': 'mensualmente',
                    'meses': 'meses',
                    'a cada 2 meses': 'cada 2 meses',
                    'bimestres': 'bimestres',
                    'a cada 3 meses': 'cada 3 meses',
                    'trimestres': 'trimestres',
                    'a cada 6 meses': 'cada 6 meses',
                    'semestres': 'semestralmente',
                    'anualmente': 'anualmente',
                    'anos': 'años',
                    'Não foi possível realizar a compra': 'No se pudo realizar la compra',
                    'Preencha todas as informações obrigatórias dos campos.': 'Complete toda la información obligatoria de los campos.',
                    'Não foi possível realizar o pagamento usando este cartão de crédito.': 'No se pudo realizar el pago con esta tarjeta de crédito.',
                    'Tente novamente utilizando outro cartão ou forma de pagamento': 'Intente nuevamente utilizando otra tarjeta o forma de pago',
                    'Selecione a quantidade de parcelas': 'Seleccione la cantidad de cuotas',
                    'Você deve escolher o método de envio': 'Debe elegir el método de envío',
                    'CVV Inválido': 'CVV inválido',
                    'Você deve preencher este campo': 'Debe completar este campo',
                    'Vencimento inválido': 'Fecha de vencimiento inválida',
                    'Selecione o ano de vencimento': 'Seleccione el año de vencimiento',
                    'Selecione o mês de vencimento': 'Seleccione el mes de vencimiento',
                    'Digite um número de cartão válido': 'Ingrese un número de tarjeta válido',
                    'Não aceitamos a bandeira informada': 'No aceptamos la marca de tarjeta proporcionada',
                    'Informe um': 'Informe un',
                    'CNPJ': 'CNPJ',
                    'Você deve informar um CEP válido': 'Debe proporcionar un código postal válido',
                    'Campo Obrigatório': 'Campo obligatorio',
                    'Informe o nome completo': 'Ingrese el nombre completo',
                    'Informe o nome e sobrenome do titular do cartão': 'Ingrese el nombre y apellido del titular de la tarjeta',
                    'Informe um telefone válido': 'Ingrese un número de teléfono válido',
                    'Não conseguimos encontrar o CEP informado.': 'No pudimos encontrar el código postal proporcionado.',
                    'Finalizar Compra': 'Finalizar compra',
                    'Segurança': 'Seguridad',
                    'Em até': 'Dentro de',
                    'dias úteis': 'días hábiles',
                    'Não existem opções de frete disponíveis para sua região': 'No hay opciones de envío disponibles para su región',
                    'Desconto': 'Descuento',
                    'Frete grátis': 'Flete gratis',
                    "Selecione a data da reserva": "Selecciona fecha de reserva",
                    "Dom": "Dom",
                    "Seg": "Lun",
                    "Ter": "Mar",
                    "Qua": "Mié",
                    "Qui": "Jue",
                    "Sex": "Vie",
                    "Sab": "Sáb",
                    "OK": "OK",
                    "Hoje": "Hoy",
                    "Ontem": "Ayer",
                    "Horas": "Horas",
                    "Minutos": "Minutos",
                    "Segundos": "Segundos",
                    "Selecionar data": "Seleccionar fecha",
                    "Tarifa de conveniência": "Costes de Gestión",
                    "Você precisa informar a data da reserva": "Es necesario informar la fecha de la reserva.",
                }
            }
        },
    });

export default i18n;