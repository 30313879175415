
export default class DataLayerService {
    
    setupGTag(gtagKeyList){
        gtagKeyList.map(key => window.gtag("config", key, {
            page_title: window.location.hash,
            page_path: window.location.hash,
        }))
    }
    setupFbq(keyList){
        keyList.map(key => window.fbq('init', key)) 
        window.fbq('track', 'PageView')
    }

    sendGtagEvent(event_name, category, payload = {}){
        window.gtag("event", event_name, {
            event_category: category,
            event_label: event_name,
            value: payload
        })
    }

    pushCheckoutMetric(event_name, payload){
        const timestamp = Date.now()

        if(window.gtag)
            this.sendGtagEvent(event_name, "checkout", JSON.stringify(payload))

        if(window.fbq){
            let event = event_name
            switch(event_name){
                case "payment_intent":
                    event = "initiatecheckout"
                    break;
                case "checkout_finished":
                    event = "purchase"
                break;
                default:
                    event = event_name
            }
            window.fbq('trackCustom', event, payload,{eventID: timestamp});
        }
    }
    pushCheckoutView(event_name, payload){
        const timestamp = Date.now()

        if(window.gtag)
            this.sendGtagEvent(event_name, "checkout", JSON.stringify(payload))

        if(window.fbq)
            window.fbq('InitiateCheckout', event_name, {eventID: timestamp});
    }
    pushConversion(payload, conversion_id){
        const timestamp = Date.now()
        if(window.gtag)
            conversion_id.map(id => window.gtag('event', 'conversion', {
                'send_to': id,
                'transaction_id': payload.transaction_id,
                'value': payload.value,
                'currency': 'BRL'
            }))

        if(window.fbq)
            window.fbq('track', 'Purchase', {currency: "BRL", value: payload.value}, {eventID: timestamp});

    }

}